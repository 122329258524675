import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import DescriptionBlock from '../components/DescriptionBlock';
import Picture from '../components/Picture';
import Layout from '../components/Layout';

export default function AboutPage(){
    let data = useStaticQuery(graphql`
    query AboutQuery  {
      markdownRemark(fileAbsolutePath: {regex: "/about/"}) {
        html
          frontmatter {
            about {
              alt
              photo {
                childImageSharp {
                  fluid{
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              title
      }
    }
  }
}
       
      
    `);
    const html = data.markdownRemark.html
    data = data.markdownRemark.frontmatter.about
    
    return(
    <Layout title={data.title}>
    <div className="content-element content-section">
        <DescriptionBlock title={data.title}>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </DescriptionBlock>
        <Picture src={data.photo.childImageSharp.fluid} alt={data.alt} type="content-section-thumbnail rounded"/>
    </div>
    </Layout>
    );
}